import React from 'react'
import {Parallax} from 'react-materialize'

const HomeParallax = () => (
    <div>
        {/* Future video coming */}
        <Parallax image={<img alt='home background' src='https://images.unsplash.com/photo-1567570473657-77e2cdb959dc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2852&q=80' />} />
        <div className="section white">
            <div className="row container">
                <h2 className="header">
                    Parallax
                </h2>
                <p className="grey-text text-darken-3 lighten-3">
                    Parallax is an effect where the background content or image in this case, is moved at a different speed than the foreground content while scrolling.
                </p>
            </div>
        </div>
        <Parallax image={<img alt='home background' src='https://images.unsplash.com/photo-1567570473657-77e2cdb959dc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2852&q=80' />} />
    </div>
);

export default HomeParallax
