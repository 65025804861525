import React from "react"

import './index.scss'
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/gophertuts.svg";
import HomeParallax from "../components/Paralax";
import Content from "../components/Content";

console.log(process.env.MY_VAR);
console.log(process.env.MY_OTHER_VAR);

const IndexPage = () => (
    <Layout>
        <SEO title="Home"/>
        <div className='Home'>
            <h1 className='Home__title'>GopherTuts</h1>
            <img src={logo} className="App-logo" alt="logo"/>
            <h1 className='center'>WORK IN PROGRESS</h1>
            <HomeParallax/>
            <Content/>
        </div>
    </Layout>
);

export default IndexPage
